import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { AppComponent } from './app.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { ClusterLayoutComponent } from './layouts/cluster-layout/cluster-layout.component';
import { GmLayoutComponent } from './layouts/gm-layout/gm-layout.component';
import {AddLoginService} from '../app/login/login.services';
import {GeneralService} from '../app/services/general.service';
import {SpinnerComponent} from '../app/components/spinner/spinner.component'
import {AuthService} from '../app/services/auth.service';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {AuthGuard} from '../app/services/auth.gurds';
import { AssignUserLayoutComponent } from './layouts/assignuser-layout/assignuser-layout.component';
import { MeasurerLayoutComponent } from './layouts/measurer-layout/measurer-layout.component';

import { DatePipe } from '@angular/common';
import {MatPaginatorModule} from '@angular/material/paginator';
import{AngularFireStorage}from"@angular/fire/compat/storage"
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AudtierLayoutComponent } from './layouts/auditer-layout/auditer-layout.component';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbar, MatToolbarModule } from '@angular/material/toolbar';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxGaugeModule } from 'ngx-gauge';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { PackagesComponent } from './packages/packages.component';
import { AddBranchesComponent } from './add-branches/add-branches.component';
import { GlobalErrorHandler } from './services/global-error-handler';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { MatCardModule } from '@angular/material/card';
import { ChatAiComponent } from './ChatAi/ChatAi/ChatAi.component';
import { AddModuleComponent } from './add-module/add-module.component';
import { AddItemTypeComponent } from './Add-ItemType/Add-ItemType.component';
import { GlobalStorageService } from './services/GlobalStorage.service';
export function initGlobalStorage(service: GlobalStorageService) {
  return () => {
    // Service constructor will handle initialization
  };
}
@NgModule({
  imports: [
    MatToolbarModule,
    MatCardModule,
    MatTooltipModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatSelectModule,
    MatTableModule,
    MatDialogModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatRadioModule,
    MatIconModule,
    MatListModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    NgxGaugeModule,
    NgSelectModule,
    MatCheckboxModule
    
  ],
  declarations: [															
    AppComponent,
    GmLayoutComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    ClusterLayoutComponent,
    AssignUserLayoutComponent,
    MeasurerLayoutComponent,
    AudtierLayoutComponent,
      PackagesComponent,
      AddBranchesComponent,
      AddItemTypeComponent,
      
   ],
  providers: [
    GlobalStorageService,
    {
      provide: APP_INITIALIZER,
      useFactory: initGlobalStorage,
      deps: [GlobalStorageService],
      multi: true
    },
    AngularFireStorage,AuthGuard,AngularFirestore,AngularFireAuth,AuthService, GeneralService, AddLoginService, DatePipe, SpinnerComponent,HttpClient,{ provide: ErrorHandler, useClass: GlobalErrorHandler }],
  bootstrap: [AppComponent],
  entryComponents:[]
})
export class AppModule {}
