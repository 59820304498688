// global-storage.service.ts
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { APP_CONFIG } from './app.config';

@Injectable({
  providedIn: 'root'
})
export class GlobalStorageService {
  private static instance: GlobalStorageService;
  private encryptionKey: string;
  private originalSetItem: (key: string, value: string) => void;
  private originalGetItem: (key: string) => string | null;

  constructor() {
    if (GlobalStorageService.instance) {
      return GlobalStorageService.instance;
    }
    
    this.encryptionKey = APP_CONFIG.storageKey;
    this.originalSetItem = localStorage.setItem.bind(localStorage);
    this.originalGetItem = localStorage.getItem.bind(localStorage);
    this.setupGlobalStorage();
    GlobalStorageService.instance = this;
  }

  private setupGlobalStorage(): void {
    const self = this;

    // Store the original methods with proper binding
    localStorage.setItem = function(key: string, value: string): void {
      const encrypted = self.encrypt(value);
      self.originalSetItem.call(localStorage, key, encrypted);
    };

    localStorage.getItem = function(key: string): string | null {
      const value = self.originalGetItem.call(localStorage, key);
      if (!value) return null;
      return self.decrypt(value);
    };
  }

  private encrypt(value: string): string {
    return CryptoJS.AES.encrypt(value, this.encryptionKey).toString();
  }

  private decrypt(encrypted: string): string | null {
    try {
      return CryptoJS.AES.decrypt(encrypted, this.encryptionKey)
        .toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.warn('Decryption failed:', error);
      return null;
    }
  }

  public setEncryptionKey(newKey: string): void {
    const tempStorage: Record<string, string> = {};
    
    // Use original methods to access raw encrypted data
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key) {
        const encryptedValue = this.originalGetItem.call(localStorage, key);
        if (encryptedValue) {
          const decryptedValue = this.decrypt(encryptedValue);
          if (decryptedValue) {
            tempStorage[key] = decryptedValue;
          }
        }
      }
    }

    // Update key
    this.encryptionKey = newKey;

    // Clear and re-encrypt with new key
    localStorage.clear();
    Object.entries(tempStorage).forEach(([key, value]) => {
      this.originalSetItem.call(localStorage, key, this.encrypt(value));
    });
  }
}