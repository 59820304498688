


import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from "../../services/auth.service";

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [

    { path: '/measurerdashboard', title: 'My measurers',  icon: 'assignment_turned_in', class: '' },
    { path: '/dr-mo-ai-measurer', title: 'Dr.Mo',  icon: 'face_retouching_natural', class: '' },

    // { path: '/analysis', title: 'Analysis',  icon: 'bar_chart', class: '' },
    // { path: '/reports', title: 'Reports',  icon: 'content_paste', class: '' },
    // { path: '/downloads', title: 'Downaloads',  icon: 'file_copy', class: '' },
    // { path: '/actionplan', title: 'Action Plan',  icon: 'chat', class: '' },
    // { path: '/addassignuser', title: 'Add Assign User',  icon: 'supervised_user_circle', class: '' },

];

@Component({
  selector: 'app-navbarmeasureruser',
  templateUrl: './navbarmeasureruser.component.html',
  styleUrls: ['./navbarmeasureruser.component.css']
})
export class NavbarMeasurerComponent implements OnInit {
  menuItems: any[];

  constructor(private router: Router,private ngzone:NgZone,private AuthService:AuthService) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    console.log(this.menuItems)
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
  GotoChangePassword(){
    this.router.navigateByUrl('/changepassword');
  
  }
  
  logout() {
this.AuthService.logout();
}
}

