


import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from "../../services/auth.service";

declare const $: any;
interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  children?: RouteInfo[]; // Add children property
}
export const ROUTES: RouteInfo[] = [

    { path: '/dashboard-gm', title: 'Dashboard',  icon: 'dashboard', class: '' },
    { path: '/analysis-gm', title: 'Analysis',  icon: 'bar_chart', class: '' },
    { path: '/actionplan-gm', title: 'Action Plan',  icon: 'chat', class: '' },



    {
      title: 'Docments',
      icon: 'attach_file',
      class: 'nav-item-dropdown',
      path:"",
      children: [
        { path: '/reports-gm', title: 'Audit Reports', icon: 'content_paste', class: '' },
        { path: '/downloads-gm', title: 'Forms', icon: 'file_copy', class: '' },
  
      ]
  },
    {
      title: 'Lab',
      icon: 'science',
      class: 'nav-item-dropdown',
      path:"",
      children: [
        { path: '/testresults-gm', title: 'Add Test Result', icon: 'biotech', class: '' },
        { path: '/labreport-gm', title: 'Reports', icon: 'summarize', class: '' }
  
      ]
  },
    {
        title: 'Monitoring',
        icon: 'device_thermostat',
        class: 'nav-item-dropdown',
        path:"",
        children: [
          { path: '/monitiringdashboard-gm', title: 'Monitoring Dashboard', icon: 'speed', class: '' },
          { path: '/addmeasureitems-gm', title: 'Add Monitoring Item', icon: 'kitchen', class: '' },
            { path: '/measurerreports-gm', title: 'Reports', icon: 'summarize', class: '' }
        ]
    },
    {
      title: 'Users',
      icon: 'people',
      class: 'nav-item-dropdown',
      path:"",
      children: [        
        { path: '/addpartner-gm', title: 'Add Partner GM',  icon: 'manage_accounts', class: '' },
        { path: '/addauditer-gm', title: 'Add Auditor ',  icon: 'manage_accounts', class: '' },
        { path: '/addassigner-gm', title: 'Add Assigner',  icon: 'manage_accounts', class: '' },
        { path: '/addusermeasurer-gm', title: 'Add Measurer',  icon: 'manage_accounts', class: '' },
       
      ]
  },
  { path: '/dr-mo-ai-gm', title: 'Dr.Mo',  icon: 'face_retouching_natural', class: '' },


];

@Component({
  selector: 'app-navbargm',
  templateUrl: './navbargm.component.html',
  styleUrls: ['./navbargm.component.css']
})
export class NavbarGmComponent implements OnInit {
  menuItems: any[];

  constructor(private router: Router,private ngzone:NgZone,private AuthService:AuthService) { }

  ngOnInit() {
    this.menuItems = ROUTES;
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
  toggleDropdown(menuItem: any) {
    menuItem.open = !menuItem.open;
}
GotoChangePassword(){
  this.router.navigateByUrl('/changepassword');

}

logout() {
this.AuthService.logout();


}
}

